$url: '../image/';
$fonts-size: 16px;
$color-black:  #212121;
$color-blue: #5ac5cb;
$color-red: #f55050;
$color-black-50: #616161;
$color-black-30: #bdbdbd;
$color-black-20: transparentize($color-black-30, .5);
$color-black-10: #f6f6f6;
$fontArsenal: 'Arsenal', sans-serif;
body {
    select{
        padding-right: 20px;
        -moz-appearance:none;
        -webkit-appearance: none;
        -ms-appearance: none;
        appearance: none !important;
        background: url($url + "ico-arrow-down.png") center right no-repeat #fff !important;
        &:focus{
            border-color: $color-black-50;
            outline: none;
        }
    }
    /* Button, Heading */
    .btn{
        border-radius: 0;
        color: #fff;
        text-align: center;
        transition: .3s;
        border: none;
        text-transform: uppercase;
        font-size: $fonts-size;
    }
    .btn-red,.btn-blue{
        padding: 10px;
        position: relative;
        z-index: 1;
        overflow: hidden;
        min-width: 40px;
        max-width: 300px;
        height: 40px;
        font-weight: 700;
    }
    .btn-red{
        background: $color-red;
        min-width: 200px;
        &.btn-red-sm{
            min-width: 40px;
        }
    }
    .btn-blue{
        background: $color-blue;
        &:hover{
            color: #fff;
            background: #4da8ad;
        }
        &:focus{
            color: #fff !important;
        }
    }
    .btn-b{
        min-width: 270px;
        @media (max-width: 991px) and (min-width: 768px){
            min-width: 240px;
        }
        @media (max-width: 767px){
            min-width: 180px;
        }
    }
    .btn-170{
      min-width: 170px;
    }
    .btn-wishlist, .btn-compare{
        min-width: 40px;
        margin-left: 10px !important;
        @media (max-width: 767px){
            margin: 0 2px;
        }
    }
    .head-border{
        &:before, &:after{
            content: '';
            display: block;
            width: 14px;
            height: 1px;
            background-color: $color-black-10;
            margin: 11px;
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
    h1.head-module{
        @media (max-width: 767px) {
            margin-top: 20px !important;
        }
    }
    .left-head-module{
        @media (max-width: 767px){
            text-align: left;
        }
    }
    .head-module{
        color: $color-black;
        font-size: 33px;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        margin: 0 0 40px 0;
        line-height: 30px;
        word-break: break-word;
        &:before, &:after{
            background-color: $color-black;
            position: absolute;
            top: 7px;
        }
        &:before{
           left: -45px;
        }
        &:after{
           right: -45px;
        }
        &.head-middle{
            font-weight: 600;
            font-size: $fonts-size;
            color: $color-black-50;
            margin-top: 30px;
        }
        & + hr{
            margin-top: 0;
            margin-bottom: 0;
        }
        @media (max-width: 1199px) and (min-width: 992px){
            font-size: 23px;
            line-height: 1.1;
            &:before, &:after{
                top: 1px;
            }
        }
        @media (max-width: 991px) and (min-width: 768px){
            font-size: 20px;
            line-height: 1.1;
            &:before, &:after{
                top: 1px;
            }
        }
        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 15px;
            &:before, &:after{
                display: none;
            }
        }
    }
    #column-right .head-module, #column-left .head-module{
        @extend .link-product;
        &:before, &:after{
            display: none;
        }
    }
    #column-right .left-head-module, #column-left .left-head-module{
        text-align: left !important;
    }
    /* Button */

    /*Arrow module*/
    .arrow-left, .arrow-right{
        display: block;
        width: 49px;
        height: 74px;
        background-image: url($url + "arrow.png");
        background-repeat: no-repeat;
        background-position: 0 0;
        &:hover{
            background-image: url($url + "ico-arrow.png");
            background-position: 0 0;
        }
        &.arrow-product{
            height: 49px;
            background-position-y: -35px;
            &:hover {
                background-position-y: -110px;
            }
        }
        @media (max-width: 767px) {
            background-image: url($url + "ico-arrow.png");
        }
    }
    .arrow-right{
        background-position: -50px 0;
        &:hover{
            background-image: url($url + "ico-arrow.png");
            background-position: -50px 0;
        }
        &.arrow-product{
            height: 49px;
            background-position-y: -35px;
            &:hover {
                background-position-y: -110px;
            }
        }
    }
    /*Arrow module*/

    /* Featured */
    .slider-prod-owl{
        text-align: center;
        &.owl-carousel{
            .product-layout{
                padding: 0;
                width: 270px;
                max-width: 100%;
            }
        }
        .product-layout{
            padding: 0 13px 20px 13px;
            display: inline-block;
            position: relative;
            width: 270px;
        }
    }
    .product-thumb{
        position: relative;
        max-width: 270px;
        margin: 0 auto;
        &:hover{
            .button-group{
                bottom: 10px;
                opacity: 1;
            }
            .image{
                img{
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }
        }
        .image{
            overflow: hidden;
            position: relative;
            -webkit-transition: .6s;
            -moz-transition:  .6s;
            -ms-transition:  .6s;
            -o-transition:  .6s;
            transition:  .6s;
            min-height: 120px;
            display: table;
            width: 100%;
            text-align: center;
            &>a{
                vertical-align: middle;
                display: table-cell;
                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    box-shadow: inset 0 0 32px rgba(0,0,0,0.1);
                }
            }
            img{
                -webkit-transition: .6s;
                -moz-transition:  .6s;
                -ms-transition:  .6s;
                -o-transition:  .6s;
                transition:  .6s;
                max-width: 100%;
                display: inline-block;
            }
        }
        .price{
            margin-bottom: 0;
            line-height: 17px;
        }
        .head-product{
            text-align: center;
            margin-top: 18px;
            margin-bottom: 18px;
            max-height: 38px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @media (max-width: 767px) {
                height: 18px;
                -webkit-line-clamp: 1;
                margin-top: 10px;
                margin-bottom: 7px;
            }
        }
        .button-group{
            position: absolute;
            opacity: 0;
            bottom: -60px;
            left: 0;
            right: 0;
            text-align: center;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            -webkit-transition: .3s;
            -moz-transition:  .3s;
            -ms-transition:  .3s;
            -o-transition:  .3s;
            transition:  .3s;
            .btn{
                height: 40px;
                min-width: 40px;
                flex: 0 0 auto;
                .fa-heart{
                    font-size: $fonts-size + 2;
                }
                .fa-retweet{
                    font-size: 20px;
                }
                .text-button{
                    text-transform: uppercase;
                }
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
    #column-right .product-thumb, #column-left .product-thumb{
        @media (max-width: 1199px) and (min-width: 767px) {
            .button-group {
                padding: 20px 5px;
                .btn {
                    width: 100%;
                    &:first-child, &:nth-child(2){
                        display: none;
                    }
                    padding: 10px 5px;
                    .text-button {
                        font-size: 13px;
                    }
                }
            }
        }
        &:hover:after{
            display: none;
        }
    }
    .rating{
        position: absolute;
        top: 20px;
        right: 20px;
        .fa{
            font-size: $fonts-size;
            color: $color-red;
            width: 13px;
        }
    }
    .link-product{
        text-transform: uppercase;
        text-decoration: none;
        line-height: 1.05;
        display: block;
        font-size: 16px;
    }
    .price{
        text-transform: none;
        @extend .link-product;
        .price-old{
            font-weight: 400;
            color: $color-red;
            text-decoration: line-through;
            padding-left: 10px;
            display: inline-block;
        }
        .price-tax{
            color: $color-black-30;
            font-weight: 400;
            padding-top: 20px;
        }
    }
    /* Featured */

    /* content bottom Slider */
    .content-bottom,.content-top{
        .slider{
            .slider-text{
                .slider-head{
                    @media (max-width: 767px) {
                        line-height: 30px;
                    }
                    span{
                        @media (max-width: 1199px) and (min-width: 992px){
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
    /* content bottom Slider */

    /* HTML module icon text */
    .table-icon{
        background-color: $color-black-10;
        border-top: none;
        margin-bottom: 0;
        tr{
            td{
                padding: 40px 50px 30px 50px;
                border-top: none;
                @media (max-width: 991px) and (min-width: 768px){
                    padding: 30px 20px;
                }
                @media (max-width: 767px) {
                    display: block;
                }
            }
        }
    }
    .col-3{
        .table-icon{
            tr{
                td{
                    @media (max-width: 1199px) and (min-width: 768px){
                        display: block;
                    }
                }
            }
        }
    }
    #column-right, #column-left{
        .table-icon{
            tr{
                td{
                    display: block;
                }
            }
        }
    }
    /* HTML module icon text */

    /* Carusel */
    .carusel{
        margin: 0;
        background-color: $color-black-10;
        padding: 40px;
        img{
            margin: 0 auto;
            filter: grayscale(1);
            padding: 0 5px;
        }
    }
    /* Carusel */

    /* Footer */
    footer{
        background-color: $color-black;
        padding: 40px 0 0 0;
        color: $color-black-30;
        .footer_info{
            @media (max-width: 767px) {
               clear: left;
            }
            @media (max-width: 480px) {
                width: 100%;
            }
        }
        .top-menu_link{
            margin-bottom: 20px;
            color: $color-black-50;
            font-weight: bold;
        }
        ul{
            &>li{
                &>a{
                    padding: 3px 0;
                    display: inline-block;
                }
            }
        }
        a{
            color: #bdbdbd;
            text-decoration: none;
            font-size: $fonts-size;
            &:focus{
              color: $color-black-10;
            }
            &:hover{
                color: #eeeeee;
            }
        }
        .footer_contact{
            p{
                margin-bottom: 5px;
                line-height: 25px;
                color: $color-black-50;
                .fa{
                    padding-right: 10px;
                    color: $color-black-50;
                }
            }
            @media (max-width: 480px) {
                width: 100%;
            }
        }
        .power{
            margin-top: 40px;
            background-color: $color-black-50;
            color: $color-black-30;
            padding: 20px 0 10px 0;
            a{
                color: $color-black-30;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    /* Footer */

    /* breadcrumb */
    .breadcrumb{
        background: transparent;
        padding: 10px 0 0 0;
        margin-bottom: 0;
        a{
            color: $color-black-30;
            font-size: $fonts-size - 2;
            text-decoration: none;
            &:hover{
                color: $color-red;
            }
        }
        &>li+li:before{
            content: '\f105';
            font: normal normal normal 14px/1 FontAwesome;
        }
        & + div{
            #column-left, #column-right{
                &>.item-block:first-child{
                    margin-top: 25px;
                }
            }
        }
    }
    /* breadcrumb */

    /* Category Page */
    .product-category{
        .product-layout{
            margin-bottom: 40px;
            @media (max-width: 480px){
                width: 100%;
            }
        }
    }
    #content.col-sm-12{
        .product-category{
            .product-layout{
                margin-bottom: 40px;
                @media (max-width: 480px){
                    width: 100%;
                }
                &.product-grid{
                    &:nth-child(4n+5) {
                        clear: left;
                    }
                    @media (min-width: 992px) and (max-width:1200px){
                        &:nth-child(4n+5) {
                            clear: none;
                        }
                        &:nth-child(3n+4) {
                            clear: left;
                        }
                    }
                    @media (min-width: 768px) and (max-width:991px){
                        &:nth-child(3n+4) {
                            clear: none;
                        }
                        &:nth-child(3n+3) {
                            clear: none;
                        }
                        &:nth-child(2n+3) {
                            clear: left;
                        }
                    }
                    @media (max-width:767px){
                        &:nth-child(3n+4) {
                            clear: none;
                        }
                        &:nth-child(3n+3) {
                            clear: none;
                        }
                        &:nth-child(2n+3) {
                            clear: left;
                        }
                    }
                }
            }
        }
    }
    .col-2{
        .product-category {
            .product-layout {
                &.product-grid {
                    &:nth-child(3n+4) {
                        clear: left;
                    }
                    @media (min-width: 992px) and (max-width: 1200px) {
                        &:nth-child(3n+4) {
                            clear: none;
                        }
                        &:nth-child(2n+3) {
                            clear: left;
                        }
                    }
                    @media (min-width: 768px) and (max-width: 991px) {
                        &:nth-child(3n+4) {
                            clear: none;
                        }
                        &:nth-child(3n+3) {
                            clear: none;
                        }
                        &:nth-child(2n+3) {
                            clear: left;
                        }
                    }
                    @media (max-width: 767px) {
                        &:nth-child(3n+4) {
                            clear: none;
                        }
                        &:nth-child(3n+3) {
                            clear: none;
                        }
                        &:nth-child(2n+3) {
                            clear: left;
                        }
                    }
                }
            }
        }
    }
    .col-3{
        .product-category {
            .product-layout {
                &.product-grid {
                    &:nth-child(2n+3) {
                        clear: left;
                    }
                }
            }
        }
    }
    .top-sort-panel{
        margin: 0 15px 40px 15px;
        padding: 15px 10px;
        background: $color-black-10;
        font-size: $fonts-size - 2;
        color: $color-black;
        #list-catalog{
            padding-left: 0;
        }
        &:after{
            display: table;
            content: '';
            clear: both;
        }
        .label-sort{
            width: auto;
            background: transparent;
            border: none;
            border-radius: 0;
            box-shadow: none;
            font-size: $fonts-size - 2;
        }
        .group-right{
            padding-left: 30px;
            margin-bottom: 1px;
            margin-top: 1px;
            @media (max-width: 500px) {
                max-width: 148px;
                padding-left: 5px;
            }
        }
        .form-control, .btn-sort{
            height: 32px;
            background: #fff;
            border: none;
            border-radius: 0;
            box-shadow: none;
            font-size: $fonts-size - 2;
        }
        .form-control{
            padding-right: 17px;
            border: 1px solid $color-black-30;
            color: $color-black-50;
        }
        .btn-sort{
            background: transparent;
            line-height: 32px;
            .fa{
                color: $color-black;
                font-size: 20px;
            }
            &.active, &:hover{
                .fa{
                    color: $color-red;
                }
            }
        }
        #input-limit, #input-sort{
            min-width: 80px;
            color: $color-black;
            @media (max-width: 767px) {
                min-width: 60px;
            }
        }
    }
    .description-category{
        text-align: justify;
        line-height: 18px;
        margin-top: 40px;
        margin-bottom: 80px;
    }
    //product-list

    .product-list{
        .product-thumb{
            border: none;
            border-bottom: 1px solid #bdbdbd;
            max-width: 100%;
            display: flex;
            .image{
                background: transparent;
                margin-right: 20px;
                flex: 1 0 260px;
                position: static;
                max-width: 260px;
                min-height: 300px;
                .button-group{
                    opacity: 1;
                    bottom: 10px;
                    margin-left: 260px;
                    padding-bottom: 30px;
                    justify-content: center;
                    .btn{
                        margin: 0 5px;
                    }
                }
                a{
                    &:after{
                        display: none;
                    }
                }
            }
            .caption{
                padding-bottom: 80px;
                flex: 1 0 100px;
                .description-list{
                    display: block !important;
                }
            }
            .rating{
                left: 20px;
                max-width: 100px;
            }
            .head-product{
                margin-bottom: 20px;
                margin-top: 0;
            }
            .price{
                margin-top: 20px;
            }
            @media (max-width: 767px) {
                display: block;
                .image{
                    img{
                        margin: 0 auto;
                    }
                    .button-group{
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .col-sm{
        .label-sort, .left-sort-panel{
            display: none;
        }
        .right-sort-panel{
            width: 100% !important;
        }

    }
    /* Category Page */

    /* Pagination */
    .pagination{
        display: block;
        background: transparent;
        border-top: 1px solid #eee;
        border-radius: 0;
        &>li{
            display: inline-block;
            &>a{
                border: none;
                border-radius: 0;
                color: $color-black;
                font-size: $fonts-size - 2;
                background: transparent;
                &:hover, &>.active>span, &>.active>span:hover{
                    color: $color-red !important;
                    background: transparent !important;
                }
                @media (max-width: 767px){
                    font-size: 20px;
                }
            }
        }
        &>.active>span{
            background: transparent !important;
            color: $color-red !important;
            border: none;
            @media (max-width: 767px){
                font-size: 20px;
            }
        }
    }
    /* Pagination */

    /* Left, Right column */
    #column-left, #column-right{
        .head-module{
            font-size: 20px;
            color: $color-black-50;
            text-align: left;
        }
        .product-thumb{
            border-bottom: none;
            .price{
                margin-bottom: 0;
            }
        }
        .row-bunner{
            margin-left: 0;
            margin-right: 0;
            .owl-item{
                padding: 0;
            }
        }
    }
    /* Left, Right column */

    /* category module */
    .category-module{
        .top-menu_link{
            display: block;
            line-height: 30px;
            margin-top: 3px;
            text-decoration: none;
            color: $color-black;
            font-size: $fonts-size;
            &.active, &:hover{
                color: $color-red;
                text-decoration: none;
            }
            &.top-menu_sub{
                text-transform: none;
                margin-top: 5px;
                padding-left: 30px;
                position: relative;
                line-height: 20px;
                &:before{
                    content: '_';
                    display: block;
                    position: absolute;
                    top: -7px;
                    left: 15px;
                }
            }
        }
    }
    /* category module */

    /* Default filter */
    .heading-module{
        font-weight: 600;
        font-size: $fonts-size;
        text-transform: uppercase;
        color: $color-black-50;
        margin-bottom: 18px;
    }
    .filter-name{
        font-weight: 600;
        font-size: $fonts-size;
    }
    .filter-group{

    }
    .filter-footer{
        .btn{
            width: 100%;
        }
    }
    /* Default filter */

    /* checkbox */
    .checkbox-label{
        padding: 2px 10px 0 25px;
        position: relative;
        &:hover{
            color: $color-red;
            .check:after{
                border: 1px solid $color-red;
            }
        }
        .check:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 5px;
            background: #fff;
            width: 17px;
            height: 17px;
            border: 1px solid $color-black-30;
            color: $color-red;
            font-family: FontAwesome;
            font-size: 18px;
            text-align: center;
        }
        input[type="checkbox"]:disabled{
           & + .check:after{
               opacity: .3;
           }
        }
        input[type="checkbox"]:checked + .check:after{
            content: '\f00c';
            font-size: 14px;
            line-height: 14px;
        }
        input[type="radio"], input[type="checkbox"]{
            opacity: 0;
        }
        input[type="radio"] + .check:after{
            border-radius: 50%;
        }
        input[type="radio"]:checked + .check:before{
            content: '';
          background: $color-red;
          position: absolute;
          left: 5px;
          top: 10px;
          z-index: 2;
          width: 7px;
          height: 7px;
          display: block;
          border-radius: 50%;
        }
    }
    /* checkbox */

    /* Product Page */
    .product-page{
        margin-top: 30px;
        .btn-group{
            line-height: 14px;
            @media (max-width: 767px){
                margin-bottom: 20px;
            }
        }
    }
    .col-btn-cart{
        clear: left;
    }
    .col-3{
        .product-page{
            display: flex;
            flex-wrap: wrap;
            .col-rating{
                order: 0;
                margin-bottom: 10px;
                width: 100%;
            }
            .col-gallery{
                order: 1;
            }
            .col-info{
                order: 2;
                width: 100%;
            }
        }
    }
    .col-2{
        .col-gallery{
            width: 375px;
            @media (max-width: 480px){
                max-width: 100%;
            }
        }
        .col-info, .col-rating{
            @media (min-width: 768px) and (max-width:991px){
                width: 100%;
            }
            @media (min-width: 992px) and (max-width:1230px){
                width: 48.333333%;
            }
            @media (min-width:1231px){
                max-width: 58.33333333%;
            }
        }
    }
    h1.head-product{
        margin-top: 0 !important;
        line-height: 35px;
        margin-bottom: 15px;
        display: block;
    }
    .button-link{
        background: transparent;
        border: none;
        font-size: 14px;
        color: $color-black;
        padding: 0;
        .fa{
            padding-right: 5px;
        }
        span{
            border-bottom: 1px dashed $color-black;
        }
        &:last-child{
            margin-left: 25px;
        }
        &:hover{
            span{
                border-bottom: none;
            }
        }
        &:focus, &:active{
            outline: none;
        }
    }
    .col-gallery{
        float: left;
        width: 390px;
        padding-left: 15px;
        padding-right: 15px;
        &>.thumbnails{
            max-width: 370px;
            margin-bottom: 0;
            @media (max-width: 767px){
                max-width: 100%;
            }
        }
        @media (max-width: 767px){
            max-width: 100%;
        }
    }
    .col-info{
        @media (min-width: 992px) and (max-width:1200px){
            max-width: 580px;
        }
    }
    .thumbnails{
        position: relative;
        .thumbnail{
            border-radius: 0;
            border-color: $color-black-10;
            &:hover{
                border-color: $color-black-30;
            }
        }
        & + .rating{
            right: 45px;
        }
        .image-additional{
            float: left;
            width: 25%;
            min-width: 80px;
            &>a{
                margin: 10px 15px 0 15px;
            }
        }
    }
    .top-product-info, .top-product-info a{
        font-size: $fonts-size;
        text-decoration: none;
    }
    .price{
        li{
            display: inline-block;
        }
    }
    .price-product{
        margin-top: 30px;
        .price-new, .price-old{
            font-size: 34px;
            font-weight: 600;
        }
        .price-new{
            padding-right: 30px;
        }
        .price-old{
            padding-left: 0;
        }
    }
    .product-info-bottom{
        // .form-control{
        //     @extend .input-grey;
        // }
        .checkbox, .radio{
            margin-top: 0;
            margin-bottom: 0;
        }
        .control-label{
            font-weight: 600;
            margin-bottom: 0;
            line-height: 16px;
        }
        .form-group{
            margin-bottom: 30px;
        }
    }
    .date, .time, .datetime{
        .btn{
            height: 40px;
        }
    }
    .quantity-contain{
        display: inline-block;
        position: relative;
        margin-right: 25px;
        .quantity{
            // @extend .input-grey;
            height: 40px;
            padding: 10px;
            margin-bottom: 0;
            width: 55px;
            border: 1px solid $color-black-30;
        }
        .quantity_plus, .quantity_minus{
            position: absolute;
            right: 4px;
            height: 50%;
            padding: 4px 1px;
            font-size: $fonts-size + 2;
            line-height: 5px;
            z-index: 9;
            &:hover{
                .fa{
                    color: $color-red;
                    cursor: pointer;
                }
            }
            .fa{
                font-size: 12px;
                line-height: 12px;
            }
        }
        .quantity_plus{
            top: 0;
        }
        .quantity_minus{
            bottom: 0;
        }
    }
    .form-button-cart{
        margin-top: 25px;
        &>div>div{
            margin-top: 5px;
            margin-bottom: 5px;
        }
        #button-cart{
            margin-top: 5px;
            margin-bottom: 5px;
        }
        @media (max-width: 991px) and (min-width: 768px){
            .btn-group{
                float: none !important;
                .btn-wishlist{
                    margin-left: 0;
                }
            }
            .quantity-contain{
                margin-right: 4px;
            }
            .btn-wishlist, .btn-compare{
                margin: 0;
                margin-left: 4px !important;
            }
        }
    }
    .descript-product{
        margin-bottom: 80px;
        margin-top: 40px;
    }
    .nav-tabs{
        border-bottom: none;
        @media (max-width: 767px){
            text-align: left;
        }
        &>li{
            float: none;
        }
        &>li.active>a, &>li.active>a:focus, &>li.active>a:hover,&>li>a:hover,.nav>li>a:focus, .nav>li>a:hover{
            border-color: transparent;
            color: $color-red;
            background: transparent;
        }
        &>li>a{
            padding: 4px 5px;
            font-weight: 700;
            text-decoration: none;
            font-size: 16px;
            @media (max-width: 767px){
                padding-left: 0;
            }
        }
        & .head-module{
            margin: 20px 20px 25px 0;
            @media (max-width: 767px){
                margin: 5px 20px 5px 0;
            }
        }
        &.tabs-border {
            border-bottom: 1px solid #eee;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                border-color: transparent;
            }
            & > li.active > a, & > li.active > a:focus, & > li.active > a:hover, & > li > a:hover, .nav > li > a:focus, .nav > li > a:hover {
                border-color: #eee;
                border-bottom-color: transparent;
                background-color: #fff;
                @media (max-width: 767px) {
                    border-color: transparent;
                }
            }
            & > li > a {
                padding: 10px 30px;
                border-radius: 0;
                text-transform: none;
                @media (max-width: 991px) and (min-width: 768px){
                    padding: 10px;
                }
                @media (max-width: 767px) {
                    padding: 10px 0;
                }
            }
            & .head-module {
                margin: 0 1px -1px 0;
            }
        }

    }
    .head-module.head-middle{
        &.tab-xs{
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    #column-right, #column-left{
        .nav-tabs{
            margin-bottom: 30px;
            & .head-module{
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }
    .table-spec{
        border-color: transparent;
        &>thead>tr>td{
            background-color: $color-black-10;
            border-color: #f6f6f4;
            font-weight: 600;
            border-right: 2px solid #fff;
            padding: 8px 25px;
            &:last-child{
              border-right: none;
            }
            @media (max-width: 1199px){
                padding: 8px;
            }
        }
        &>tbody>tr>td, &>tfoot>tr>td{
            border-color: $color-black-10;
            vertical-align: middle;
            padding: 15px 25px;
            border-top: none;
            border-bottom: 2px solid $color-black-10;
            border-right: 2px solid #fff;
            min-width: 150px;
            &:last-child{
              border-right: none;
            }
            @media (max-width: 1199px){
                padding: 8px;
                min-width: 125px;
            }
        }
        .price{
            display: table-cell;
        }
    }
    .review-page{
        .fa{
            font-size: $fonts-size;
            color: #f55050;
            width: 13px;
        }
    }
    .col-3{
        .col-sm{
            width: 100%;
        }
    }
    .col-sm{
        .form-button-cart{
            .btn-group{
                float: none !important;
                .btn-wishlist{
                    margin-left: 0;
                }
            }
        }
    }
    .rating-product{
        position: static;
        padding-left: 0;
        display: inline-block;
        line-height: 16px;
        margin-right: 25px;
        margin-bottom: 0;
        .fa{
            color: $color-black-30;
            line-height: 16px;
        }
    }
    /* Product Page */

    /* Compare Page */
    .compare{
        .rating{
            position: static;
            .fa{
                height: 17px;
            }
        }
        &>thead>tr>td{
          padding-left: 30px;
          padding-right: 30px;
        }
        &>tbody>tr>td{
            padding: 14px 30px;
          @media screen and (max-width: 1199px){
            white-space: normal !important;
            .btn{
              min-width: 110px;
              margin-top: 5px;
            }
          }
            @media (max-width: 767px){
                min-width: 150px;
            }
        }
        .last-tr{
            &>td{
                padding: 25px 8px;
                border-color: transparent;
                border-top: 3px solid $color-black-10;
            }
        }
    }
    .remove-link{
      display: block;
      color: #bb0000;
      margin-top: 10px;
      &:hover{
        color: #bb0000;
      }
    }
    /* Compare Page */

    /* Contact Page */
    .form-contact{
        position: relative;
        .fa{
            position: absolute;
            top: 0;
            right: 15px;
            height: 40px;
            width: 40px;
            padding: 12px 5px;
            text-align: center;
            color: $color-black-30;
            opacity: 0;
            -webkit-transition: .3s;
            -moz-transition: .3s;
            -ms-transition: .3s;
            -o-transition: .3s;
            transition: .3s;
        }
        input:focus{
            padding-right: 40px;
           & + .fa{
                opacity: 1;
            }
        }
    }
    .col-contact{
        margin-bottom: 20px;
        .head-middle{
            margin-top: 0;
            margin-bottom: 0;
            line-height: 16px;
        }
        .info-text{
            color: $color-black-30;
            font-size: $fonts-size - 2;
            line-height: 14px;
        }
        address{
            & > span{
                max-width: 400px;
                display: inline-block;
            }
        }
        .text-bold{
            font-weight: 600;
            font-size: 24px;
            line-height: 20px;
        }
        .text-open{
            font-size: 20px;
        }
        p{
            margin-bottom: 0;
            line-height: 1.25;
        }
        &.comment-text{
            margin-top: 40px;
            margin-bottom: 80px;
        }
        .map-link{
            font-size: 14px;
            margin-left: 30px;
            border-bottom: 1px solid $color-black-30;
            font-weight: 100;
            &:hover, &.active{
                border-bottom: 1px dashed $color-black-30;
                cursor: pointer;
            }
            
        }
        .map-container{
            display: none;
            iframe{
                border: 1px solid $color-black-30 !important;
            }
        }
        .map-hide{
            display: none;
        }
        .map-link-target{
            text-decoration: none;
            &:hover{
                color: $color-black-50;
            }
            .fa{
                font-size: 10px;
                padding-left: 5px;
            }
        }
    }
    div.required .control-label:after {
        content: ' *';
        color: #bb0000;
        font-weight: bold;
    }
    /* Contact Page */

    /* Information page */
    .information-page{
        margin-bottom: 80px;
        ul{
            padding-left: 20px;
            li{
                list-style-type: none;
                &:before{
                    content: '\f105';
                    font-family: FontAwesome;
                    color: $color-blue;
                    padding-right: 10px;
                    font-size: $fonts-size;
                }
            }
        }
    }
    /* Information page */

    /* Search page */
    .row-search{
        margin-bottom: 40px;
        input, select{
            margin-bottom: 5px;
        }
    }
    /* Search page */

    /* Cart page */
    .checkout-cart{
        .panel-cart {
            padding-left: 45px;
        }
    }
    .panel-cart{
        @media (max-width: 767px){
            padding-left: 0;
        }
        .panel{
            border-radius: 0;
            border-color: transparent;
            box-shadow: none;
        }
        .panel-heading{
            border-radius: 0;
            background: transparent;
            border: none;
            padding: 2px 0;
            a{
                text-decoration: none;
                border-bottom: 1px dashed $color-black;
            }
            @media (max-width: 991px){
                text-align: right;
                padding-right: 15px;
            }
            @media (max-width: 767px){
                padding-right: 0;
            }
        }
        .panel-body{
            border-top-color: transparent !important;
            padding-left: 0;
            padding-right: 0;
            .form-horizontal .control-label{
                text-align: left;
            }
        }
    }
    .text-price{
        font-size: $fonts-size + 2;
        line-height: 15px !important;
        @media (max-width: 767px) {
            font-size: $fonts-size + 2;
        }
    }
    .table-cart{
        tr:last-child>td{
            font-size: 24px;
            line-height: 18px !important;
            font-weight: 700;
        }
        @media (max-width: 767px){
            tr{
                padding-bottom: 20px;
                &:last-child{
                    text-align: right;
                    &>td{
                        display: inline-block;
                        &:last-child{
                            padding-left: 10px;
                        }
                    }
                }
                &>td{
                    display: block;
                    font-size: $fonts-size - 2;
                    padding: 0;
                    &:last-child{
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media (max-width: 767px){
        .pull-xs {
            float: none !important;
            text-align: center;
            margin: 5px;
            a{
                min-width: 215px;
            }
        }
    }
    .buttons-chechout{
        .checkbox{
            display: inline-block;
            .checkbox-label{
                padding-top: 5px;
                margin-left: 5px;
            }
        }
    }
    #collapse-checkout-confirm{
        .btn{
            background: $color-blue;
            margin-right: 32px;
            margin-top: 60px;
            &:hover{
                background: $color-red;
            }
            @media (max-width: 767px){
                margin-right: 0;
            }
        }
    }

    &.checkout-cart{
        .quantity-contain{
            margin-right: 0;
        }
        .td-remove{
            min-width: 50px;
            .fa-times{
                font-size: 16px;
            }
        }
        .link-back{
            font-size: 16px;
        }
        .buttons{
            margin-top: 60px;
            margin-bottom: 80px;
        }
    }
    .col-3{
        .row-cart-bottom{
            text-align: right;
            &>div{
                width: 100% !important;
            }
            & + .buttons{
                text-align: right;
                width: 100% !important;
                padding: 0;
            }
        }
    }
    /* Cart page */

    /* Easy chechout */
    .tab-checkout{
      margin-bottom: 80px;
    }
    .order-row{
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .dashed-text{
        text-decoration: none;
        border-bottom: 1px dashed;
        &:hover{
            border-bottom-style: solid;
        }
    }
    .order-product {
        background: $color-black-10;
        padding: 30px 30px;
        max-width: 370px;
        margin-top: 43px;
        margin-bottom: 80px;
        @media (max-width: 480px){
            padding: 10px;
        }
        img {
            max-width: 155px;
            height: auto;
            margin-right: 30px;
        }
        .goodsList {
            margin-bottom: 30px;
            & > div:first-child {
                min-width: 140px;
                @media (max-width: 480px){
                    min-width: 100px;
                }
            }
            & > div:last-child {
                width: 170px;
                @media (max-width: 991px) and (min-width: 768px){
                    width: 135px;
                }
                @media (max-width: 480px){
                    width: 130px;
                }
            }
            &:after {
                content: '';
                display: table;
                clear: both;
            }
        }
        #totals_block, .change-order {
            &:before {
                content: '';
                width: 100%;
                height: 2px;
                background: #fff;
                display: block;
                position: absolute;
                right: 0;
                left: 0;
            }
        }
        .change-order a{
            padding-top: 27px;
            display: inline-block;
        }
        .easy-totals {
            padding: 27px 0;
            table{
                margin-bottom: 0;
                &>tbody{
                    &>tr{
                        &>td{
                            padding: 0;
                        }
                        &:last-child{
                            &>td{
                                padding-top: 15px;
                                font-size: 21px;
                                font-weight: 600;
                            }
                        }

                    }
                }
            }
        }
        @media (max-width: 767px){
            float: left !important;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
    #order_methods{
        & + div{
            .checkbox{
                margin-bottom: 20px;
            }
        }
    }
    #shipping_pickup{
        margin-top: 20px;
        border: 1px solid $color-black-30;
    }
    .label-addres{
        position: absolute;
        top: -30px;
        @media (max-width: 767px){
            position: relative;
            top: 0;
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
    .dop-info-addres{
        position: absolute;
        font-size: 12px;
        color: $color-black-30;
    }
    .pass-forgot{
        margin-top: 20px;
    }
    .telephone{
        .form-group{
            margin-bottom: 0;
            @media (max-width: 767px) {
                margin-bottom: 15px;
            }
        }
    }
    .checkbox-phone{
        margin-top: 5px;
        margin-bottom: 20px;
    }
    /* Easy chechout */

    /* Default chechout */
    .checkout-default{
        margin-bottom: 80px;
        .panel-title{
            font-weight: 700;
            text-decoration: none;
            font-size: 16px;
            text-transform: uppercase;
            a{
                font-size: 16px;
            }
        }
        .head-middle{
            margin: 10px 0 5px 0;
        }
        .form-horizontal .radio{
           padding-bottom: 7px;
        }
        .btn-b{
            min-width: 277px;
        }
        .table-spec{
            border: 1px solid transparent;
        }
        .table-spec>tfoot{
            &>tr{
                &:first-child{
                    &>td{
                        padding-top: 20px;
                    }
                }
                &>td{
                    padding: 8px 33px;
                }
            }
        }
        .panel-title{
            display: inline-block;
            border-bottom: 1px dashed #212121;
            a{
                border-bottom: none;
            }
        }
        @media (max-width: 991px){
            &.panel-cart .panel-heading {
                text-align: left !important;
            }
        }
    }
    /* Default chechout */

    /* Module Account */
    .account-menu{
        position: relative;
        text-align: right;
        z-index: 9;
        &:hover{
            .account-menu-list{
                height: auto;
            }
        }
        .account-menu-btn{
            overflow: visible;
            width: 270px;
            max-width: 100%;
            margin-bottom: 20px;
            &:hover{
                background: $color-blue;
            }
        }
        .account-menu-list{
            &>a{
                display: block;
                color: $color-black-50;
                padding: 10px;
                text-transform: uppercase;
                text-align: left;
            }
        }
    }
    /* Module Account */

    /* Account Page*/
    .table-account{
        thead{
            @media (max-width: 767px){
                display: none;
            }
        }
        tr>td{
            vertical-align: top !important;
            @media (max-width: 767px){
                display: block;
            }
        }
        a{
            color: $color-black-50;
            padding: 5px;
            display: block;
        }
    }
    .col-account{
        .head-module{
            display: block;
            a{
                font-size: inherit;
            }
            &.head-middle{
                margin: 0 0 10px 0;
                line-height: 16px;
            }
        }
        .form-horizontal .control-label{
            text-align: left;
        }
        fieldset{
            margin-bottom: 40px;
        }
        .col-forgotten{
            margin-bottom: 10px;
            @media (max-width: 767px){
                text-align: center;
            }
        }
    }
    .col-account-2{
        max-width: 572px;
    }
    .checkbox-register{
        &.checkbox{
            padding-top: 0;
        }
        .checkbox-reg{
            opacity: 0;
        }
        .checkbox-label .check:after{
            top: 5px;
        }
        .btn{
            margin-top: 30px;
        }
    }
    .checkbox-label, a{
        font-size: 14px;
    }
    .pass-link{
        font-size: 14px;
        text-decoration: none;
        color: $color-black;
        padding-top: 3px;
    }
    .dop-text{
        color: $color-black-30;
        font-size: 14px;
    }
    .button-eye{
      .btn-blue{
        padding: 5px 7px;
        min-width: 32px;
        height: 32px;
      }
    }
    .link-back{
        padding-left: 40px;
        padding-right: 40px;
        @media (max-width: 480px){
            padding-right: 0;
        }
    }
    .form-sm{
        max-width: 270px;
    }
    &.account-account{
        .table-spec{
            @media (max-width: 480px){
                thead{
                    display: none;
                }
                &>tbody>tr>td{
                    display: block;
                }
            }
        }
    }
    &[class*="account-"]{
        #content{
            margin-bottom: 80px;
        }
    }
    /* Account Page*/
  .top-btn{
    width: 40px;
    height: 40px;
    background: url($url + "ico.png") no-repeat -26px 8px $color-black-30;
    position: fixed;
    bottom: 70px;
    right: 50px;
    z-index: 99;
    text-align: center;
    display: none;
    &:hover{
      background-color: $color-red;
      cursor: pointer;
      -webkit-transition: .3s;
      -moz-transition: .3s;
      -ms-transition: .3s;
      -o-transition: .3s;
      transition: .3s;
    }
  }
    /* showcase module*/
    .showcase{
        padding: 40px 0 40px 0;
        border-top: 1px solid $color-black-30;
        border-bottom: 1px solid $color-black-30;
        .product-thumb{
            border-bottom: none;
            &:hover{
                &:after{
                    transform: scale(0, 0);
                }
            }
            .image{
                min-height: 150px;
                display: table;
                width: 100%;
                &>a{
                    display: table-cell;
                    vertical-align: middle;
                    @media (max-width: 767px){
                        display: block;
                    }
                }
                img{
                    width: auto;
                    margin: 0 auto;
                }
            }
            .head-product{
                height: auto;
                max-height: 64px;
            }
            .price{
                margin-bottom: 0;
            }
        }
        .information-layout{
            overflow: hidden;
            .information-sm {
                .descript {
                    display: none;
                }
                &.product-thumb{
                    min-height: 150px;
                    .caption {
                        .flex-descript {
                            height: 100%;
                        }
                    }
                }
            }
            .product-thumb{
                background-color: $color-black-10;
                padding: 20px 15px;
                display: flex;
                height: 100%;
                .descript{
                    flex: 1 0 auto;
                    word-break: break-word;
                    font-size: $fonts-size - 2;
                    text-align: left;
                }
                .caption{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    .bunner-link{
                        flex: 0 0 auto;
                        display: block;
                    }

                }
                .flex-descript{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: stretch;
                    height: 100%;
                }
            }
            .head-product{
                flex: 0 0 auto;
                margin-top: 0;
                text-align: left;
                margin-bottom: 10px;
            }
            .bunner-link{
                font-size: 14px;
                text-decoration: none;
                &:hover{
                    color: $color-red;
                    &:after, &:before{
                        background-color: $color-red;
                    }
                }
            }
            .head-border{
                &:after, &:before{
                    background-color: $color-black-50;
                    display: inline-block;
                    margin: 4px 13px;
                }
            }
        }
        .nav-tabs{
            .head-module{
                margin-top: 0;
                margin-bottom: 40px;
                line-height: 40px;
                &>a{
                    padding: 0;
                    line-height: 16px;
                }
            }
        }
        &.showcase-left{
            display: flex;
            align-items: stretch;
            @media (max-width: 767px){
                display: block;
            }
            .nav-tabs{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 30px;
                @media (max-width: 767px){
                    display: block;
                }
                .head-module{
                    display: block;
                    margin-bottom: 0;
                    @media (max-width: 767px){
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                    &>a{
                        display: inline-block;
                        padding-right: 0;
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                }
            }
        }
        &.showcase-all, &.showcase-left-all{
            .tab-pane{
                .wrap-panel{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    &>div{
                        flex: 1 0 295px;
                        max-width: 300px;
                        margin: 40px 0 0 0;
                        @media (max-width: 767px){
                            flex: 1 0 240px;
                            margin-top: 20px;
                            padding: 0 15px;
                        }
                    }
                }
            }
            .nav-tabs{
                line-height: 16px;
                .head-module{
                    margin-bottom: 0;
                    &>a{
                        line-height: 12px;
                    }
                }
            }
        }
        &.showcase-left-all{
            display: flex;
            align-items: stretch;
            padding-top: 0;
            @media (max-width: 767px){
                display: block;
            }
            .nav-tabs{
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-right: 30px;
                @media (max-width: 767px){
                    display: block;
                }
                .head-module{
                    display: block;
                    margin-bottom: 0;
                    @media (max-width: 767px){
                        display: inline-block;
                        margin-bottom: 5px;
                    }
                    &>a{
                        display: inline-block;
                        padding-right: 0;
                        padding-top: 4px;
                        padding-bottom: 4px;
                        line-height: 16px;
                    }
                }
            }
            .tab-pane{
                .wrap-panel{
                    justify-content:flex-start;
                    &>div{
                        @media (min-width: 768px) and (max-width: 992px){
                            flex: 0 0 255px;
                            padding: 0 15px;
                        }
                    }
                    .category-layout, .manufacturer-layout{
                        .product-thumb .head-product{
                            margin-bottom: 0;
                        }
                    }
                    @media (max-width: 767px){
                        justify-content: center;
                    }
                }
            }
        }
        @media (max-width: 767px){
            border-top: none;
            border-bottom: none;
            padding-bottom: 0;
            padding-top: 0;
        }
    }
    .col-3{
        .showcase-left{
            .nav-tabs{
                text-align: left;
            }
        }
    }
    #column-right, #column-left {
        .showcase {
            border-bottom: none;
            border-top: none;
            padding: 0;
            .information-layout .product-thumb .caption .bunner-link{
                &:before, &:after{
                    display: none;
                }
            }
            &.showcase-left, &.showcase-left-all {
                display: block;
                & > div, .nav-tabs{
                    width: 100%;
                    float: none;
                }
            }
            &.showcase-all, &.showcase-left-all{
                .tab-pane{
                    .wrap-panel>div{
                        max-width: 100%;
                    }
                    &>div{
                        max-width: 100%;
                    }
                }
                .tab-pane .wrap-panel>div{
                    &:first-child{
                        margin-top: 0;
                    }
                    margin: 0;
                    &.product-layout, &.information-layout{
                        margin-bottom: 20px;
                    }
                    .head-product{
                        margin-bottom: 20px;
                    }
                    &:last-child{
                        margin-bottom: 0;
                        .head-product{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .tab-pane .wrap-panel>div{
                &:last-child{
                    margin-bottom: 0;
                    .head-product{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .content-fullup, .content-fullunder {
        .showcase{
            padding-left: 15px;
            padding-right: 15px;
            @media (max-width: 767px){
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    /* showcase module*/

    /* news module*/
    .content-fullup, .content-fullunder{
        .breaking_news{
            padding: 0 15px;
        }
    }
    .breaking_news{
        .pagination{
            margin-bottom: 0;
        }
    }
    .news{
        margin-bottom: 20px;
        &>div{
            font-size: 14px;
        }
    }
    .news-description{
        font-size: 14px;
        line-height: 18px;
        letter-spacing: .5px;
        margin-bottom: 10px;
    }
    .col-news{
        .image{
            margin-bottom: 20px;
        }
        &.col-news-1{
            padding-right: 30px;
            @media (max-width: 767px){
                border-right: none;
                padding-right: 15px;
            }
        }
        &.col-news-2{
            padding-left: 30px;
            @media (max-width: 767px){
                border-left: none;
                padding-left: 15px;
                left: auto;
            }
        }
    }
    .col-3, #column-left, #column-right{
        .col-news{
            width: 100%;
            border-right:none;
            border-left:none;
            &.col-news-1{
                padding-right:15px;
            }
            &.col-news-2{
                padding-left:15px;
            }
        }
    }
    /* news module*/
    .stickers{
        z-index: 9;
        position: absolute;
        background-color: $color-red;
        color: #fff;
        font-size: $fonts-size - 2;
        text-transform: uppercase;
        top: 13px;
        left: 0;
        padding: 5px 20px;
        &.stickers-action{
            background-color: #b52828;
        }
    }
    #column-left, #column-right{
        .stickers{
            @media (max-width: 1199px) and (min-width: 768px){
                top: 40px;
            }
        }
    }
    /* socials module*/
    .socials{
        @media (max-width: 767px){
            text-align: left;
        }
        a{
            display: inline-block;
            color: $color-black-50;
            margin: 20px 30px 8px 0;
            @media (max-width: 767px){
                margin-right: 20px;
            }
            .fa{
                font-size: 18px;
            }
            span{
                font-size: 14px;
                padding-left: 5px;
            }
            &:hover{
                color: $color-black-30;
            }
        }
    }

    /* manufacturer */
    .manufacturer{
        margin-bottom: 40px;
    }
    .row-flex{
        display: flex;
        flex-wrap: wrap;
    }
    .manufactur-list{
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        a{
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
            display: block;
        }
        .img-contain {
            min-height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            img{
                display: block;
                margin: 0 auto 10px auto;
                -ms-filter: grayscale(100%);
                filter: grayscale(100%);
                -webkit-transition: .3s;
                -moz-transition: .3s;
                -ms-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
            }
        }
        &:hover{
            img{
                -ms-filter: grayscale(0);
                filter: grayscale(0);
            }
            a{
                color: $color-black;
            }
        }
    }
    footer{
        .socials{
            a{
                &:hover{
                    color: $color-black-10;
                }
            }
        }
    }
}
.ie9{
    body{
        .slider{
            .bunner-text .slider-head{
                border-bottom: 20px;
                span.bunner-link{
                    position: absolute;
                    bottom: 15px;
                    left: 0;
                    right:0;
                    &:before, &:after{
                        display: none;
                    }
                }
            }
        }
        .showcase-all.showcase{
            .tab-pane .wrap-panel > div{
                min-width: 295px;
                &:nth-child(3n+4){
                    clear: both;
                }
                @media (max-width: 1229px){
                    &:nth-child(3n+4){
                        clear: none;
                    }
                    &:nth-child(2n+3){
                        clear: both;
                    }
                }
            }
        }
    }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

}